// Single Exhibition

.single_exhibition {
	margin-top: 96px;
	border-bottom: 1px solid #e8e8e8;
	.title_top{
		text-align: center;
		width: 100%;
		margin-bottom: 65px;
		h1{
			color: #25282e;
			font-size: 50px;
			font-weight: 400;
			margin-bottom: 21px;
			margin-top: 43px;
		}
		.value_mid{
			.exhibition-date{
				font-size: 20px;
				color: #777777;
				font-weight: 400;
			}
		}
		.back_event{
			font-size: 18px;
			line-height: 22px;
			color: #999999;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-transition: all .3s;
			&:before{
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f053";
				padding-right: 7px;
				font-size: 12px;
			}
			&:hover{
				color: #c1b696;
			}
		}
	}

	.exhibition_content{
		display: flex;
		@media(max-width: 768px){
			display: block;
		}
		.wrap_content{
			width: 807px;
			padding-right: 40px;
			@media(max-width: 768px){
				padding-right: 0;
				width: 100%;
			}
			.feature_img{
				
			}
			.content{
				// margin-left: -10px;
				// margin-right: -10px;
			}	
		}
		.line{
			width: 363px;
			position: relative;
			@media(max-width: 768px){
				width: 100%;
			}
			.wrapper_order{
				padding-left: 40px;
				padding-bottom: 40px;
				position: sticky;
				top: 170px;
				bottom: auto;
				&:before{
					position: absolute;
					content: "";
					width: 1px;
					background-color: #dfdfdf;
					left: 0;
					top: 0;
					height: 673px;
					@media(max-width: 768px){
						content: none;
					}
				}
				@media(max-width: 768px){
					padding-left: 0;
					padding-bottom: 60px;
				}
				.order_ticket{
					.member,.button_order{
						height: 50px;
						line-height: 50px;
						border-width: 2px;
						border-style: solid;
						font-size: 16px;
						display: inline;
						display: flex;
						justify-content: center;
						text-transform: uppercase;
						-webkit-transition: all .3s;
						transition: all .3s;
						&:hover{
							background: #c1b696;
							border-color: #c1b696;
						}
					}
					.member{
						margin-bottom: 20px;
						color: #25282e;
						border-color: #e5e5e5;
						&:hover{
							color: #fff;
						}
					}
					.button_order{
						background: #25282e;
						border-color: #25282e;
						color: #fff;
						font-weight: 500;
						margin-bottom: 35px;
					}
					ul.info_order{
						padding: 0;
						li{
							list-style: none;
							margin-bottom: 22px;
							span{
								font-size: 17px;
								color: #777777;
								font-weight: 400;
								line-height: 24px;
								display: block;
								&.label{
									color: #25282e;
								}
							}
							a{
								color: #777777;
								font-size: 17px;
								font-weight: 400;
							}
						}
					}
					.share_social{
						display: flex;
						align-items: center;
						cursor: pointer;
						position: relative;
						padding-top: 17px;
						width: fit-content;
						i{
							display: flex;
							justify-content: center;
							align-items: center;
							width: 40px;
							height: 40px;
							background-color: #bfbfbf;
							color: #fff;
							border-radius: 50%;
							margin-right: 10px;
						}
						&:hover i {
							background: #c1b696;
						}
						span{
							color: #222428;
							font-size: 16px;
						}
						.share-social-icons{
							padding-left: 0px;
							list-style-type: none;
							background-color: #fff;
							min-width: 160px;
							-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
							-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
							box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
							position: absolute;
							bottom: 100%;
							left: 0;
							visibility: hidden;
							opacity: 0;
							-webkit-transition: all .3s;
							transition: all .3s;
							li{
								padding-left: 18px;
								border-bottom: 1px solid #e8e8e8;
								a{
									padding-bottom: 8px;
									padding-top: 8px;
									display: inline-block;
									font-size: 15px;
									line-height: 24px;
									color: #777777;
								}
								&:hover{
									a{
										color: #c1b696;
									}
								}
							}
						}
						&:hover{
							.share-social-icons{
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}
		}

	}
}

.gallery_exhibition{
	.titlte_glr{
		h3{
			font-size: 40px;
			color: #25282e;
			text-transform: capitalize;
			font-weight: 400;
			margin-top: 29px;
			margin-bottom: 42px;
		}
	}
	.gallery_slide{
		background: #25282e;
		padding: 130px 0 80px 369px;
		position: relative;
		@media(max-width: 1499px){
			padding-left: 40px;
			padding-right: 40px;
		}
		@media(max-width: 1024px){
			padding-left: 10px;
			padding-right: 10px;
		}
		.items{
			cursor: pointer;
		}
		.owl-nav{
			position: absolute;
			top: 45px;
			.owl-prev, .owl-next{
				&:focus{
					outline: none;
				}
				span{
					display: none;
				}
			}
			.owl-prev{
				background: url('../../img/arrow-left.png') no-repeat center left;
				width: 42px;
				height: 42px;
				margin-right: 5px;
			}
			.owl-next{
				background: url('../../img/arrow-right.png') no-repeat center right;
				width: 42px;
				height: 42px;
				margin-left: 5px;
			}
		}
		
		// Fix autoWidth Owl Carousel
		.owl-stage {
			display: -webkit-box!important;
			display: -moz-box!important;
			display: -ms-box!important;
			display: box!important;
		}
	}
}

.related_post{
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 39px;
	.related_ex{
		h2{
			font-size: 40px;
			color: #25282e;
			font-weight: 400;
			text-transform: capitalize;
			margin-top: 111px;
			margin-bottom: 50px;
		}
	}
}
.related_ex, .exhibition_arc{	
	.wrap-content{		
		display: -webkit-flex;
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;			
		@media(max-width: 1024px){
			margin-left: -10px;
			margin-right: -10px;
		}
		.post-items{
			width: 33.33333%;
			text-align: left;
			margin-bottom: 65px;
			padding-left: 20px;
			padding-right: 20px;
			@media(max-width: 1024px){
				padding-left: 10px;
				padding-right: 10px;
			}
			@media(max-width: 768px){
				width: 50%;
			}
			@media(max-width: 767px){
				width: 100%;
			}
			a{
				img{
					transition: opacity .3s cubic-bezier(.455,.03,.515,.955);
					-webkit-transition: opacity .3s cubic-bezier(.455,.03,.515,.955);
					backface-visibility: hidden;
					-webkit-backface-visibility: hidden;					
					opacity: 1;
					@media( max-width: 768px ){
						width: 100%;
					}
				}
				&:hover{
					img{
						opacity: .85;
					}
					
				}
			}
			.content{
				padding-top: 32px;
				h3{
					font-size: 28px;
					font-weight: 400;
					margin-bottom: 12px;
					margin-top: 10px;
					a{
						color: #25282e;
						-webkit-transition: color .3s cubic-bezier(.455,.03,.515,.955);
						transition: color .3s cubic-bezier(.455,.03,.515,.955);
						&:hover{
							color: #515358;
						}
					}
				}
				.singular_name{
					color: #999999;
					font-size: 16px;
					text-transform: uppercase;
				}
				.times{
					span{
						font-size: 18px;
						color: #777777;
					}
				}
			}
		}
	}
}


.next-prev-nav{
	border-top: 1px solid #e8e8e8;
	padding-top: 37px;
	padding-bottom: 40px;
	.nav-previous-post, .nav-next-post{
		i{
			font-size: 45px;
			color: #c1c2c3;
			-webkit-transition: all 0.3s ease-in-out 0s;
			-moz-transition: all 0.3s ease-in-out 0s;
			-ms-transition: all 0.3s ease-in-out 0s;
			-o-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
		}
		span{
			display: block;
			&.label-event{
				font-size: 16px;
				color: #999999;
				line-height: 22px;
				text-transform: uppercase;
				margin-bottom: 5px;
			}
			&.second_font{
				font-size: 24px;
				color: #25282e;
				font-weight: 400;
				line-height: 35px;
				-webkit-transition: all .3s;
				transition: all .3s;
				&:hover{
					color: #515358;
				}
			}
		}
	}
	.nav-prev, .nav-next{
		width: 50%;
		@media(max-width: 767px){
			width: 100%;
		}
	}
	.nav-prev{
		@media(max-width: 767px){
			margin-bottom: 22px;
		}
		.nav-previous-post{
			a{
				padding-left: 62px !important;
				display: block !important;
				position: relative;
				i{
					position: absolute;
					left: 0;
					top: 15%;
					&:hover{
						transform: scale(1.1);
						color: #000;
					}
				}
			}
		}
	}
	.nav-next{
		.nav-next-post{
			a{
				padding-right: 62px !important;
				display: block !important;
				position: relative;
				i{
					position: absolute;
					right: 0;
					top: 15%;
					&:hover{
						transform: scale(1.1);
						color: #000;
					}
				}
			}
		}
	}
}

// Archive Exhibition

.exhibition_arc{
	padding-bottom: 16px;
	&.element_exh{
		padding-bottom: 0;
	}
	.heading_archive_exh{
		text-align: center;
		margin-bottom: 60px;
		.title_exh{
			font-size: 60px;
			color: #25282e;
			font-weight: 400;
			margin-bottom: 14px;
			margin-top: 96px;
		}
		.desc_exh{
			padding-left: 150px;
			padding-right: 150px;
			font-size: 22px;
			color: #888888;
			@media(max-width: 768px){
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	.wrap-content{
		.post-items{
			a{
				img{
					transition: opacity .3s cubic-bezier(.455,.03,.515,.955);
					-webkit-transition: opacity .3s cubic-bezier(.455,.03,.515,.955);
					backface-visibility: hidden;
					-webkit-backface-visibility: hidden;					
					opacity: 1;
				}
				&:hover{
					img{
						opacity: .85;
					}
					
				}
			}	
			.content{
				padding-top: 24px;
			}
			&.ex2_columns{
				width: 50%;
				@media(max-width: 767px){
					width: 100%;
				}
			}
			&.ex3_columns{
				width: 33.3333%;
				@media(max-width: 768px){
					width: 50%;
				}
				@media(max-width: 767px){
					width: 100%;
				}
			}
			&.ex4_columns{
				width: 25%;
				@media(max-width: 768px){
					width: 50%;
				}
				@media(max-width: 767px){
					width: 100%;
				}
			}
			&.list{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-bottom: 60px;
				.img-exhibition{
					max-width: 31%;
					width: 100%;
					@media(max-width: 767px){
						max-width: 100%;
					}
				}
				.content{
					padding: 0 40px;
					max-width: 69%;
					width: 100%;
					@media(max-width: 767px){
						max-width: 100%;
						padding: 0;
					}
					h3{
						margin-top: 20px;
						margin-bottom: 7px;
						font-size: 32px;
					}
				}
			}			
		}
		&.owl-carousel{
			margin-left: 0;
			margin-right: 0;
			display: block;
			position: relative;
			.owl-nav {
				@media (max-width: 1500px) {
					display: none;
				}
				.owl-prev {
					position: absolute;
					left: 0;
					transform: translate(-100%, -50%);
					margin-left: -50px;
					outline: none;
					font-size: 45px;
					color: #cccccc;
					transition: .3s all linear;
					top: 136px;
					&:hover {
						color: #25282e;
						transition: 0.3s all linear;
					}
					span {
						display: none;
					}
				}
				.owl-next {
					position: absolute;
					right: 0;
					transform: translate(100%, -50%);
					margin-right: -50px;
					outline: none;
					font-size: 45px;
					color: #cccccc;
					transition: .3s all linear;
					top: 136px;
					&:hover {
						color: #25282e;
						transition: 0.3s all linear;
					}
					span {
						display: none;
					}
				}
			}

			.owl-dots {
				display: none;
				text-align: center;
				@media (max-width: 1500px) {
					display: block;
					padding-bottom: 50px;
				}
				.owl-dot {
					outline: 0;
					span {
						width: 10px;
						height: 10px;
						display: inline-block;
						background: #ccc;
						border-radius: 100%;
						margin: 0 5px;
						transition: 0.3s all linear;
					}
					&.active span {
						background: #c1b696;
					}
					&:hover span {
						background: #c1b696;
						transition: 0.3s all linear;
					}
				}
			}
			
			.post-items{
				width: auto;
				padding-left: 0;
				padding-right: 0;
				h3 {
					margin-top: 0;
				}
			}
		}
		ul.nav-tabs{
			justify-content: center;
			width: 100%;
			margin-bottom: 60px;
			border-bottom: 1px solid #e8e8e8;
			li{
				padding: 0 15px;
				a{
					font-size: 20px;
					color: #999999;
					border: 0;
					text-transform: capitalize;
					padding: 0;
					padding-bottom: 20px;
					-webkit-transition: all .3s;
					transition: all .3s;
					&.active{
						border: 0;
						border-bottom: 1px solid #c1b696;
						color: #25282e;
					}
				}
			}
		}
		&.element-arc{
			display: block;
			margin-left: 0;
			margin-right: 0;
			.tab-content{
				display: flex;
				display: -webkit-flex;
				margin-left: -15px;
				margin-right: -15px;
				flex-wrap: wrap;
				@media(max-width: 1024px){
					margin-right: -10px;
					margin-left: -10px;
				}
			}
		}
		
	}
	.ova_more_post{
		display: inline-block;
		width: 100%;
		text-align: center;
		.load-more{
			height: 50px;
			line-height: 50px;
			border: 1px solid #e5e5e5;
			padding: 14px 69px 13px 69px;
			font-size: 17px;
			color: #25282e;
			text-transform: capitalize;
			-webkit-transition: all .3s;
			transition: all .3s;
			cursor: pointer;
			&:hover{
				background: #c1b696;
				border-color: #c1b696;
				color: #fff;
			}
		}
		.image-loadmore{
			display: none;
		}
	}
	.ova-nodata, .ova-noload{
		width: 100%;
		text-align: center;
		display: none;
		span{
			height: 50px;
			line-height: 50px;
			border: 1px solid #e5e5e5;
			padding: 14px 69px 13px 69px;
			font-size: 17px;
			color: #25282e;
			text-transform: capitalize;
			-webkit-transition: all .3s;
			transition: all .3s;
			cursor: pointer;
			&:hover{
				background: #c1b696;
				border-color: #c1b696;
				color: #fff;
			}
		}
	}
	.blog_pagination{
		margin-top: 0px;
		margin-bottom: 98px;
		width: 100%;
		.pagination{
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			li{
				a{
					border-radius: 0px;
					margin-right: 0px;
					color: #25282e;
					font-size: 16px;
					line-height: 22px;
					width: 40px;
					height: 40px;
					padding: 0px;
					border: 1px solid #e5e5e5;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					border-right: 0;
				}
				&.next{
					a{
						border-right: 1px solid #e5e5e5;
					}
				}
				&.active{
					a{
						border: 1px solid #25282e;
						background-color: #25282e;
						color: #fff;
					}
				}
			}
		}
	}
}